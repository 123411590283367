import * as React from 'react'
import { strapiURL } from '../config'
import axios from 'axios'
import { navigate } from 'gatsby'
import { Spin } from 'antd'
import { serialize } from '../functions'

const JobFilterPage = ({ location }) => {
  const queryParams = new URLSearchParams(
    typeof window !== 'undefined' && window.location.search
  )
  let searchType = queryParams.get('stype')
  let searchId = queryParams.get('sid')
  let utmSource = queryParams.get('utm_source')

  React.useEffect(() => {
    if (searchType && searchId && searchType !== null && searchId !== 0) {
      let url = ''
      if (searchType === 'search') {
        url = strapiURL + '/candidate-searches/' + searchId
      } else if (searchType === 'alert') {
        url = strapiURL + '/job-alerts/' + searchId
      } else if (searchType === 'empSearch') {
        url = strapiURL + '/employer-searches/' + searchId
      }

      if (url !== '') {
        axios
          .get(url)
          .then(res => {
            if (searchType === 'empSearch') {
              runEmployerSearch(res?.data)
            } else {
              runSearch(res?.data)
            }
          })
          .catch(err => {
            console.log(err)
            navigate(`/${utmSource ? `?utm_source=${utmSource}` : ''}`)
          })
      } else {
        navigate(`/${utmSource ? `?utm_source=${utmSource}` : ''}`)
      }
    } else {
      navigate(`/${utmSource ? `?utm_source=${utmSource}` : ''}`)
    }
  })

  const runSearch = val => {
    let _vals = {
      title: val?.keyword || '',
      travel_percentage: val?.travel_percentage || '',
      compensation_type:
        val.compensation_type === 'Not Specified' ? '' : val.compensation_type,
      primary_function:
        val.primary_function === 'Not Specified'
          ? ''
          : val.primary_function?.toString(),
      product_category: val?.industry || '',
      remote_enabled: val?.remote_enabled || false,

      max_compensation: parseInt(val.max_compensation || 500),
      min_compensation: parseInt(val.min_compensation || 0),
    }

    if (val.location?.placePredict) {
      _vals.place_id = val.location.placePredict?.value?.place_id
      _vals.address = val.location.placePredict?.label
    }

    if (utmSource) {
      _vals.utm_source = utmSource
    }

    let URL = serialize(_vals)
    navigate(`/job-search/?` + URL)
  }

  const runEmployerSearch = record => {
    navigate(
      `/employer-dashboard/resume/search/${
        utmSource ? `?utm_source=${utmSource}` : ''
      }`,
      {
        state: {
          runningAlert: {
            keyword: record?.keyword,
            searchId: record?.id,
            location: record?.location,
            distance: record?.distance,
            education_level: record?.education_level,
            general_sales_experience_years_from:
              record?.general_sales_experience_years_from,
            general_sales_experience_years_to:
              record?.general_sales_experience_years_to,
            medical_sales_experience_years_from:
              record?.medical_sales_experience_years_from,
            medical_sales_experience_years_to:
              record?.medical_sales_experience_years_to,
            functions: record?.functions,
            industries: record?.industries,
            willing_travel_percentage_from:
              record?.willing_travel_percentage_from,
            willing_travel_percentage_to: record?.willing_travel_percentage_to,
            is_1099: record?.is_1099,
            relocate: record?.relocate,
          },
        },
      }
    )
  }

  return (
    <div>
      <h1 style={{ textAlign: 'center', paddingTop: '100px' }}>Please wait!</h1>
      <p style={{ textAlign: 'center' }}>
        Your search is being filtered. Please wait...
      </p>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Spin size="large" />
      </div>
    </div>
  )
}

export default JobFilterPage
